export const entryTypes = {
  MICROSITE: {
    value: 'microsite',
    attribution: 'dms',
  },
  FAB: {
    value: 'fab',
    attribution: 'dfab',
  },
  HOMEPAGE: {
    value: 'home',
    attribution: 'homepage',
  },
  CITY: {
    value: 'city',
    attribution: 'sfab',
  },
  FILTER: {
    value: 'filter',
    attribution: 'filter',
  },
  AREA: {
    value: 'area',
    attribution: 'area',
  },
  DISTRICT: {
    value: 'district',
    attribution: 'district',
  },
  REGION: {
    value: 'region',
    attribution: 'region',
  },
  COUNTRY: {
    value: 'country',
    attribution: 'country',
  },
  CONTINENT: {
    value: 'continent',
    attribution: 'continent',
  },
  HOSTEL: {
    value: 'hostel',
    attribution: 'hostel',
  },
  HOTEL: {
    value: 'hotel',
    attribution: 'hotel',
  },
  GUESTHOUSE: {
    value: 'guesthouse',
    attribution: 'guesthouse',
  },
  APARTMENT: {
    value: 'apartment',
    attribution: 'apartment',
  },
  CAMPSITE: {
    value: 'campsite',
    attribution: 'campsite',
  },
  PROPERTY: {
    value: 'sms',
    attribution: 'sms',
  },
  GROUPCHAIN: {
    value: 'groupChainLanding',
    attribution: 'groupChainLanding',
  },
  BLOG: {
    value: 'blog',
    attribution: 'blog',
  },
  BLACKFRIDAY: {
    value: 'blackFriday',
    attribution: 'blackFriday',
  },
  OTHER: {
    value: 'other',
    attribution: 'other',
  },
};
