import { useAppDownloadBanner } from '@/composables/useAppDownloadBanner.js';
import { SEGMENT_PAGE_DATA } from '@/config/analytics';
import { getCommonTracking } from '@/tracking/common';
import { TRACKING_EMPTY } from '@/tracking/variables';

export default function Tracker(trackers, nuxtApp) {
  const {
    getGtmOptions,
    getSegmentOptions,
  } = getCommonTracking(nuxtApp);

  const { getAppDownloadBannerVariation } = useAppDownloadBanner();
  const routeStore = useRouteStore();

  const currentPageKey = routeStore.getPageKey;

  const sessionStore = useSessionStore();
  const testingStore = useTestingStore();

  const webElasticSearchAutoComplete = testingStore.hasEnabledFeature('web_elasticSearchAutoComplete');
  const webAutoCompleteTracking = testingStore.hasEnabledFeature('web_autocomplete_tracking');

  const triggerAppDownloadEvent = function (eventAction) {
    const segmentPageData = SEGMENT_PAGE_DATA[currentPageKey] || SEGMENT_PAGE_DATA.other;

    if (segmentPageData.EVENT) {
      const segmentVariables = {
        event_name: segmentPageData.EVENT,
        action: eventAction,
        app_download_banner_cohort: getAppDownloadBannerVariation(currentPageKey),
      };

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    }
  };

  return {
    onLogoutClick: () => {
      const segmentVariables = {
        event_name: 'Login or Signup Event',
        action: 'Logout Successful',
      };

      trackers?.gtm.onEvent('gtmAccountLogOut');

      trackers?.segment.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onLoginClick: () => {
      const segmentVariables = {
        event_name: 'Login or Signup Event',
        action: 'Login Clicked',
        page_type: 'login',
      };

      trackers?.gtm.onEvent('gtmLoginClick');

      trackers?.segment.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onSearchSubmitted: (trackingData) => {
      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Search Submitted',
        page_type: sessionStore.getEntryPointForTracking,
        destination_district: trackingData?.destination_district || TRACKING_EMPTY,
        destination_area: trackingData?.destination_area || TRACKING_EMPTY,
        destination_region: trackingData?.destination_region || TRACKING_EMPTY,
        destination_city: trackingData?.destination_city || TRACKING_EMPTY,
        destination_city_id: trackingData?.destination_city_id || TRACKING_EMPTY,
        destination_country: trackingData?.destination_country || TRACKING_EMPTY,
        destination_continent: trackingData?.destination_continent || TRACKING_EMPTY,
        arrival_date: trackingData?.arrival_date || TRACKING_EMPTY,
        departure_date: trackingData?.departure_date || TRACKING_EMPTY,
        number_guests: trackingData?.number_guests || TRACKING_EMPTY,
        number_nights: trackingData?.number_nights || TRACKING_EMPTY,
        lead_time: trackingData?.lead_time || TRACKING_EMPTY,
        search_keywords: trackingData?.search_keywords || TRACKING_EMPTY,
        time_period_used: trackingData?.time_period_used || TRACKING_EMPTY,
        group_type: trackingData?.group_type || TRACKING_EMPTY,
        group_age_range: trackingData?.group_age_range || TRACKING_EMPTY,
        property_type: trackingData?.property_type || TRACKING_EMPTY,
        property_name: trackingData?.property_name || TRACKING_EMPTY,
        product_id: trackingData?.product_id?.toString() || TRACKING_EMPTY,
        sku: trackingData?.sku?.toString() || TRACKING_EMPTY,
        auto_sort_order_applied: trackingData?.auto_sort_order_applied || TRACKING_EMPTY,
        page_sort_order_applied: trackingData?.page_sort_order_applied || TRACKING_EMPTY,
        listing_type: trackingData?.listing_type || TRACKING_EMPTY,
      };

      trackers?.gtm.onEvent('gtmSearchSubmitted');

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onSearchActivated: (field) => {
      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Search Activated',
        activated_field: field,
        page_type: sessionStore.getEntryPointForTracking,
        search_test_id_1: webElasticSearchAutoComplete ? 'variation' : 'control',
      };

      const dataLayerVariables = {
        parameter1: field,
        parameter2: webElasticSearchAutoComplete ? 'variation' : 'control',
      };

      trackers?.gtm.onEvent('gtmSearchActivated', dataLayerVariables);
      trackers?.gtm.onEvent('gtmDefaultDateExperiment', { parameter2: sessionStore.getEntryPointForTracking });

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onAutoCompleteSearchCall: (term, results) => {
      if (!webAutoCompleteTracking) {
        return;
      }

      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Search Autocomplete Call',
        autocomplete_term: term,
        autocomplete_search_results: results?.length === 1 && results[0]?.type === 'no-results' ? 0 : results?.length,
      };

      const dataLayerVariables = {
        parameter1: term,
        parameter2: results ? results.length : 0,
      };

      trackers?.gtm.onEvent('GTMAutoCompleteSearchCall', dataLayerVariables);
      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onAutoCompleteSearchSelect: (term, item) => {
      if (!webAutoCompleteTracking) {
        return;
      }

      let country = '';
      const cityCountry = item.labelSmall.split(',');

      switch (item.type) {
        case 'city':
        case 'region':
          item.city = item.label;
          country = item.labelSmall.trimStart();
          break;
        case 'citydistricts':
        case 'property':
          item.city = cityCountry[0]?.trimStart();
          country = cityCountry[1]?.trimStart();
          break;
      }

      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Search Autocomplete Select',
        autocomplete_term: term,
        search_selected_label: item.label,
        search_selected_rank: item.rank,
        search_selected_type: item.type,
        search_selected_city: item.city,
        search_selected_country: country,
      };

      const dataLayerVariables = {
        parameter1: `${term}|${item.label}|${item.rank}`,
        parameter2: `${item.type}|${item?.city}|${country}`,
      };

      trackers?.gtm.onEvent('GTMAutoCompleteSearchSelect', dataLayerVariables);
      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onRecentDestinationClicked: () => {
      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Recent Search Clicked',
      };

      trackers?.gtm.onEvent('gtmRecentSearchClicked');

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onRecentDestinationsShown: () => {
      const segmentVariables = {
        event_name: 'Search Event',
        action: 'Recent Search Shown',
      };

      trackers?.gtm.onEvent('gtmRecentSearchShown');

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onNewsletterSubmit: (segmentVariables) => {
      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onTrackEventExample: (dataLayerVariables, segmentVariables) => {
      trackers?.gtm?.onPageView({
        ...getGtmOptions,
        ...dataLayerVariables,
      });

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onPropertyCardGalleryClick: (params) => {
      trackers?.gtm?.onEvent('gtmFABGalleryViewed', params);
    },

    onEasterEggFound: (dataLayerVariables, segmentVariables) => {
      trackers?.gtm?.onEvent('gtmError404PageEvent', dataLayerVariables);

      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onPpcRedirectExperiment: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmPPCRedirectExperiment', dataLayerVariables);
    },

    onPageFilterClick: (dataLayerVariables, segmentVariables) => {
      trackers?.gtm?.onEvent('gtmFABListFilterClick', dataLayerVariables);
      trackers?.segment?.onEvent({
        ...getSegmentOptions,
        ...segmentVariables,
      });
    },

    onNoAvailabilityExperiment: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmSPWACityNoAvailabilityBannerExperiment', dataLayerVariables);
    },

    onNewSortOrderExperiment: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmSPWANewSortOrderExperiment', dataLayerVariables);
    },

    onSustainabilityExperiment: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmSustainableBadgeCohort', dataLayerVariables);
    },

    onCityMapExperiment: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmSPWACityMapExperiment', dataLayerVariables);
    },

    onHostelSignupInteraction: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmHostelSignupClick', dataLayerVariables);
    },

    onBubbleMessageClick: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmHomeEvent', dataLayerVariables);
    },

    onCampaignBannerClick(page) {
      trackers?.gtm?.onEvent('gtmCampaignBannerClicked', { parameter1: page });
      if (page === 'homepage') {
        trackers?.segment?.onEvent({
          event_name: 'Homepage Event',
          action: 'All Deals Clicked',
          page_type: 'Homepage',
          ...getSegmentOptions,
        });
      }
    },

    onUnmissableCitiesInteraction(dataLayerVariables, segmentVariables = null) {
      trackers?.gtm?.onEvent('gtmHomeCityClick', dataLayerVariables);

      if (segmentVariables !== null) {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          ...segmentVariables,
        });
      }
    },

    onBlackFridayBannerClick(page) {
      trackers?.gtm?.onEvent('gtmBFHWBannerClicked', { parameter1: page });
    },

    onBFTermsConditionsClick(page = '') {
      trackers?.gtm?.onEvent('gtmBFTermsAndConditionClick', { parameter1: page });

      trackers?.segment?.onEvent({
        event_name: page ? 'Black Friday Page Event' : 'Homepage Event',
        action: 'Terms And Conditions Clicked',
        page_type: page ? 'blackFriday' : 'homepage',
        ...getSegmentOptions,
      });
    },
    onBFAllDealsClick(page = '') {
      trackers?.gtm?.onEvent('onBFAllDealsClick', { parameter1: page });

      trackers?.segment?.onEvent({
        event_name: 'Homepage Event',
        action: 'Black Friday All Deals Clicked',
        page_type: 'homepage',
        ...getSegmentOptions,
      });
    },

    onBFLPCityOfferClick(data) {
      const dataString = `${data?.city?.[0]?.cityName}|${data?.continent}`;
      trackers?.gtm?.onEvent('gtmBFLPCityOfferClick', { parameter1: dataString });
      trackers?.segment?.onEvent({
        event_name: 'Black Friday Page Event',
        bf_city_clicked: data?.city?.[0]?.cityName,
        bf_continent_clicked: data?.continent,
        action: 'City Section Clicked',
        page_type: 'blackFriday',
        ...getSegmentOptions,
      });
    },

    onHostelCardClick(title = 'hostel card click') {
      trackers?.gtm?.onEvent('gtmHomeEvent', { parameter1: title });
    },

    onRoamiesSectionInteraction() {
      trackers?.gtm?.onEvent('gtmRoamiesShowMe');
    },

    onAppDownloadClick(osType = '', title = 'app download click', isBlackFriday = false) {
      trackers?.gtm?.onEvent(isBlackFriday ? 'gtmBFLPAppDownload' : 'gtmHomeEvent', { parameter1: title });

      if (osType) {
        trackers?.segment?.onEvent({
          event_name: isBlackFriday ? 'Black Friday Page Event' : 'Homepage Event',
          os_button_clicked: osType,
          action: 'App Store Clicked',
          page_type: isBlackFriday ? 'blackFriday' : 'homepage',
          ...getSegmentOptions,
        });
      }
    },

    onBlogItemInteraction(dataLayerVariables, segmentVariables = null) {
      trackers?.gtm?.onEvent('gtmHomeBlogClick', dataLayerVariables);

      if (segmentVariables !== null) {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          ...segmentVariables,
        });
      }
    },

    onFAQExpand: () => {
      trackers?.gtm?.onEvent('gtmFABFAQExpand');
    },

    onFeaturedPropertyCardClick: (dataLayerVariables, segmentVariables = null) => {
      trackers?.gtm?.onEvent('gtmFeaturedPropertyClick', dataLayerVariables);

      if (segmentVariables !== null) {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          ...segmentVariables,
        });
      }
    },

    onSustainabilityIconClick: (dataLayerVariables, segmentVariables = null) => {
      trackers?.gtm?.onEvent('gtmSustainableIconClicked', dataLayerVariables);

      if (segmentVariables !== null) {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          ...segmentVariables,
        });
      }
    },

    onImageGalleryViewed: (title) => {
      trackers?.gtm?.onEvent('gtmMSGalleryViewed', { parameter1: title });

      trackers?.segment?.onEvent({
        event_name: 'Property Details Page Event',
        action: 'Gallery Clicked',
        page_type: 'staticMicrosite',
        gallery_panel: title, // header or in-page
        ...getSegmentOptions,
      });
    },

    onImageGalleryExit: () => {
      trackers?.gtm?.onEvent('gtmMSGalleryExit');
    },

    onPropertyIgnoreAvailability: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmSPWACityNoAvailabilityBannerExperiment', dataLayerVariables);
    },

    onMachineTranslationEvent: (dataLayerVariables) => {
      trackers?.gtm?.onEvent('gtmMicrositeMachineTranslation', dataLayerVariables);
    },

    onBFLPNewsletterSubscription: (email) => {
      trackers?.gtm?.onEvent('gtmBFLPNewsletterSubscribe', { parameter1: email });

      trackers?.segment?.onEvent({
        event_name: 'Black Friday Page Event',
        email,
        optin_status: true,
        action: 'Newsletter Signup Clicked',
        page_type: 'blackFriday',
        ...getSegmentOptions,
      });
    },

    onBFLPAddRemainder: () => {
      trackers?.gtm?.onEvent('gtmBFLPAddRemainder');

      trackers?.segment?.onEvent({
        event_name: 'Black Friday Page Event',
        action: 'Reminder Added',
        page_type: 'blackFriday',
        ...getSegmentOptions,
      });
    },

    onBFLPMapScroll: () => {
      trackers?.gtm?.onEvent('gtmBFLPMapScroll');

      trackers?.segment?.onEvent({
        event_name: 'Black Friday Page Event',
        action: 'Map Scrolled',
        page_type: 'blackFriday',
        ...getSegmentOptions,
      });
    },

    onBFLPDealClick: (property, index, source) => {
      trackers?.gtm?.onEvent('gtmonBFLPDealClick');
      if (property) {
        trackers?.segment?.onEvent({
          event_name: source === 'blackFriday' ? 'Black Friday Page Event' : 'Homepage Event',
          bf_property_clicked: property?.propertyId,
          bf_offer_displayed: property.tag,
          bf_carousel_position_clicked: index + 1,
          action: 'Popular Hostel Section Clicked',
          page_type: 'blackFriday',
          ...getSegmentOptions,
        });
      }
    },

    onBFLPMapPropertyClick: (property) => {
      trackers?.gtm?.onEvent('onBFLPMapPropertyClick');
      if (property) {
        trackers?.segment?.onEvent({
          event_name: 'Black Friday Page Event',
          bf_property_clicked: property?.id,
          bf_city_clicked: property?.tracking?.city,
          bf_continent_clicked: property?.tracking?.continent,
          action: 'Map Section Property Clicked',
          page_type: 'blackFriday',
          ...getSegmentOptions,
        });
      }
    },

    onBFLPWishListClick: () => {
      trackers?.gtm?.onEvent('onBFLPWishListClick');

      trackers?.segment?.onEvent({
        event_name: 'Black Friday Page Event',
        action: 'Wishlist Clicked',
        page_type: 'blackFriday',
        ...getSegmentOptions,
      });
    },

    onAppDownloadBannerOpen: () => {
      triggerAppDownloadEvent('App Download Banner Clicked');
    },

    onAppDownloadBannerDismiss: () => {
      triggerAppDownloadEvent('App Download Banner Closed');
    },

    onAppDownloadLinkClick: () => {
      triggerAppDownloadEvent('App Download Menu Clicked');
    },

    // @TODO - STATICWEB-311 - Remove all when experiment is complete
    onThirdPartyInventoryToggleChange: (isSelected, variation) => {
      const action = isSelected ? '3rd Party Toggle Changed to On' : '3rd Party Toggle Changed to Off';

      trackers?.segment?.onEvent({
        event_name: 'Search Event',
        action,
        search_test_id_4: variation,
        ...getSegmentOptions,
      });
    },
    onSeasonalAllDealsClick(page = '') {
      trackers?.gtm?.onEvent('onSeasonalAllDealsClick', { parameter1: page });

      trackers?.segment?.onEvent({
        event_name: 'Homepage Event',
        action: 'All Deals Clicked',
        page_type: 'homepage',
        ...getSegmentOptions,
      });
    },
    onSeasonalDealClick: (property, index) => {
      trackers?.gtm?.onEvent('gtmonSeasonalDealClick');
      if (property) {
        trackers?.segment?.onEvent({
          event_name: 'Homepage Event',
          sd_carousel_property_clicked: property?.propertyId,
          sd_carousel_offer_displayed: property.tag,
          sd_carousel_position_clicked: index + 1,
          action: 'Carousel Clicked',
          page_type: 'Homepage',
          ...getSegmentOptions,
        });
      }
    },

    // ### LINKUPS TRACKING EVENTS
    triggerLinkupEventAction: (extraSegmentVariables) => {
      const segmentPageData = SEGMENT_PAGE_DATA[currentPageKey] || SEGMENT_PAGE_DATA.other;

      if (segmentPageData.EVENT) {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          event_name: segmentPageData.EVENT,
          ...extraSegmentVariables,
        });
      }
    },
  };
}
