export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useRuntimeConfig();

  const {
    locale,
    locales,
  } = nuxtApp.$i18n;

  const defaultQuery = {};

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'api-key': appConfig.public.APIGEE_KEY,
  };

  const defaultParams = {};

  const makeApiCall = async (callKey, fetchUrl, headers, query, params) => {
    const result = ref(null);

    const fetchOptions = {
      headers,
      query,
      params,
    };

    try {
      if (callKey) {
        const { data: result } = await nuxtApp.runWithContext(
          async () =>
            await useAsyncData(callKey, () => $fetch(fetchUrl, fetchOptions), {
              initialCache: false,
              watch: [],
            }),
        );

        return result?.value || null;
      } else {
        const response = await $fetch(fetchUrl, fetchOptions);
        const result = Array.isArray(response) ? response : JSON.parse(response);

        if (response?.error?.value) {
          // @TODO: Check that proper errors are being logged
          console.error(`Could not fetch client side data on ${fetchUrl}`);
          return null;
        }
        return result || null;
      }
    } catch (error) {
      // @TODO: Check that proper errors are being logged
      if (error) {
        console.error(`Could not fetch data ${callKey || fetchUrl}`);
        console.error(error);
        return null;
      }
    }

    return result?.value || null;
  };

  const spapi = async (callKey, endpoint, headersObj, queryObj, paramsObj) => {
    const fetchUrl = `${appConfig.public.STATIC_PAGES_API_URL}/${endpoint}`;

    const headers = {
      ...defaultHeaders,
      ...headersObj,
    };
    const query = {
      ...defaultQuery,
      ...queryObj,
    };
    const params = {
      ...defaultParams,
      ...paramsObj,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  const hwapi = async (callKey, endpoint, headersObj, queryObj, paramsObj) => {
    const fetchUrl = `${appConfig.public.HOSTELWORLD_API_URL}/${endpoint}`;

    const headers = {
      ...defaultHeaders,
      ...headersObj,
    };
    const query = {
      ...defaultQuery,
      ...queryObj,
    };
    const params = {
      ...defaultParams,
      ...paramsObj,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  const msapi = async (callKey, endpoint, headersObj, queryObj, paramsObj) => {
    const fetchUrl = `${appConfig.public.MICROSITE_API_URL}/${endpoint}`;

    const headers = {
      ...defaultHeaders,
      ...headersObj,
    };
    const query = {
      ...defaultQuery,
      ...queryObj,
    };
    const params = {
      ...defaultParams,
      ...paramsObj,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  const acapi = async (callKey, endpoint, headers = {}, extraQuery = {}, extraParams = {}) => {
    const rwdLang = locale?.value || 'en';
    const rwdLangUrlPrefix = locales.value.find((l) => l.code === rwdLang);
    const rwdAcLangPrefix = appConfig.public[rwdLangUrlPrefix.rwdUrl];
    const rwdAcHost = appConfig.public.HOSTELWORLD_URL.replace(/www/, rwdAcLangPrefix);

    const fetchUrl = `${rwdAcHost}${endpoint}`;

    const query = {
      ...defaultQuery,
      ...extraQuery,
    };
    const params = {
      ...defaultParams,
      ...extraParams,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  const itapi = async (body = {}) => {
    const result = ref(null);

    try {
      const { data } = await useFetch('/api/iterable', {
        method: 'POST',
        body,
      });

      result.value = toValue(data);
    } catch (error) {
      // @TODO: Check that proper errors are being logged
      if (error) {
        console.error('Could not post data to iterable');
      }
      return result;
    }

    return toValue(result);
  };

  const linkupsApi = async (callKey, endpoint, headersObj, queryObj, paramsObj) => {
    const fetchUrl = `${appConfig.public.GATHERINGS_API_URL}/${endpoint}`;

    const headers = {
      ...defaultHeaders,
      ...headersObj,
    };
    const query = {
      ...defaultQuery,
      ...queryObj,
    };
    const params = {
      ...defaultParams,
      ...paramsObj,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  const redirectionapi = async (callKey, endpoint, headersObj, queryObj, paramsObj) => {
    const fetchUrl = `${appConfig.public.SPWA_REDIRECTION_API}/${endpoint}`;

    const headers = {
      ...defaultHeaders,
      ...headersObj,
    };
    const query = {
      ...defaultQuery,
      ...queryObj,
    };
    const params = {
      ...defaultParams,
      ...paramsObj,
    };

    return await makeApiCall(callKey, fetchUrl, headers, query, params);
  };

  return {
    provide: {
      api: {
        spapi,
        hwapi,
        msapi,
        acapi,
        itapi,
        linkupsApi,
        redirectionapi,
      },
    },
  };
});
